// General stylesheets are imported here.
// Beware of all SASS code that generates CSS output only ONCE nad here.
import "@incinity/pinko/styles/index.scss";

// Create component map to register decorator
// Decorators are not executed on import statement
import { Messages } from "@incinity/hiyo/messages";
import messagesCs from "./assets/messages/messages.cs.json";
import { Templates } from "@incinity/hiyo/templates.js";
import { PinkoApp } from "./components/common/pinko-app/pinko-app.js";
import { PinkoContext } from "./context/pinko-context.js";

export const DEFINE_MAP: any = {
}

// Add localization
Messages.add("cs", messagesCs);
Messages.add("en", messagesCs);
Messages.setLang("cs");

// Register Handlebars helpers
Templates.registerHelpers();

// Create app component end attach to DOM
document.body.replaceChildren(new PinkoApp(new PinkoContext()));