import "./pinko-app.scss";
import template from "./pinko-app.hbs";
import { Log } from "@incinity/hiyo/log.js";
import { component } from "@incinity/hiyo/decorators.js";
import { Component } from "@incinity/hiyo/component.js";
import { PinkoContext } from "../../../context/pinko-context.js";
import { WsClient } from "../../clients/ws-client/ws-client.js";

@component(template)
export class PinkoApp extends Component<PinkoContext> {

    // Properties
    public ws: WsClient;
    public data: any;

    public async onCreate() {
        Log.i("Welcome to Pinko app");

        // WebSocket client
        this.ws = new WsClient({
            wsUrl: "wss://api.invipo.ai/ws"
        });

        // Ws onEvent handler
        this.ws.onData = (data: any) => {
            // Assign data
            this.data = data;

            // Add countdowns
            for (let group of data.groups) {
                for (let event of group.events) {
                    event.counter1 = Math.round((new Date(this.data.timestamp).getTime() - new Date(event.startTime).getTime()) / 1000);
                    event.counter2 = Math.round((new Date(event.minEndTime).getTime() - new Date(this.data.timestamp).getTime()) / 1000);
                    event.counter3 = Math.round((new Date(event.maxEndTime).getTime() - new Date(this.data.timestamp).getTime()) / 1000);
                    event.counter4 = Math.round((new Date(event.likelyTime).getTime() - new Date(this.data.timestamp).getTime()) / 1000);
                }
            }
            // Redraw
            //if (this.ws.messageCount <= 1) {
                console.info(this.data);

                this.render();
            //}
        };

    }

}