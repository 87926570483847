import { Context } from "@incinity/hiyo/context.js";

export class PinkoContext extends Context {

    constructor() {
        // Call super constructor
        super();

        // Custom arrangements?
        /*this.invipo = new InvipoClient({
            host: "https://invipo.idshk.cz/api"
        });*/
    }

}

