import { WsClientOptions } from "./types.js";
import { Log } from "@incinity/hiyo/log.js";

export class WsClient {

    // Constants
    static readonly RECONNECT_TIMEOUT = 30; // seconds
    static readonly HEARTBEAT_INTERVAL = 30; // seconds

    // Properties
    public options: WsClientOptions;
    public socket: WebSocket =  null;
    public messageCount = 0;
    public reconnectTimeout: any = null;

    // Event handling methods
    public onData(data: any): void {}

    constructor (options: WsClientOptions) {
        this.options = options;

        // Connect to server
        this.connect();
    }

    public connect(): void {
        // Already connected?
        if (this.socket && (this.socket.readyState == WebSocket.OPEN || this.socket.readyState == WebSocket.CONNECTING)) {
            Log.i("WsClient: Could not connect again, already connected");
            return;
        }

        // WebSocket client connection
        this.socket = new WebSocket(this.options.wsUrl);

        // Clear reconnection timeout if set
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        // Send is alive event periodically to prevent timeout
        setInterval(() => {
            if (this.socket?.readyState == WebSocket.OPEN) {
                // Send heartbeat message to keep connection open
                this.socket.send("Ping");
            }
        }, WsClient.HEARTBEAT_INTERVAL * 1000);

        // Bindings
        this.socket.onmessage = (message: MessageEvent) => this.onMessage(message);
        this.socket.onopen = () => this.onOpen();
        this.socket.onclose = () => this.onClose();
    }

    public disconnect(): void {
        if (!this.socket) {
            Log.w(`Could not disconnect, not connected`);
            return;
        }

        try {
            // Cleat reconnection timeout if set
            if (this.reconnectTimeout) {
                clearInterval(this.reconnectTimeout);
                this.reconnectTimeout = null;
            }

            // Reset all bindings
            this.socket.onmessage = null;
            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onerror = null;

            // Disconnect
            this.socket.close();
            this.socket = null;
        }
        catch (error) {
            Log.e(`WsClient: Error while disconnecting from server ${this.options.wsUrl} (${error.message})`);
        }
    }

    private onMessage(message: MessageEvent) {
        // Counter for internal use
        this.messageCount++;

        // Debug enabled?
        if (this.options.debug) {
            Log.i(`WsClient: ${message} (#${this.messageCount})`);
            Log.i(message);
        }

        // Count info
        if (this.messageCount % 1000 == 0) {
            Log.i(`WsClient: ${this.messageCount} messages received in total`);
        }

        // Call onEvent handler
        this.onData(JSON.parse(message.data));
    }

    private onOpen() {
        Log.i(`WsClient: Connected to ${this.options.wsUrl}`);
    }

    private onClose() {
        Log.i(`WsClient: Connection closed by server, will try to reconnect in ${ WsClient.RECONNECT_TIMEOUT} second(s)`);

        // Set timeout to connect again
        if (!this.reconnectTimeout) {
            this.reconnectTimeout = setTimeout(() => this.connect(), WsClient.RECONNECT_TIMEOUT * 1000);
        }
    }

    public setDebug(debug: boolean) {
        this.options.debug = debug;

        if (this.options.debug) {
            Log.i(`WsClient: debug=true (now all events coming through WebSocket will be traced)`);
        }
    }

}
